<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VTextArea">
    <VSLabel v-if="props.label" :tooltip="props.labelTooltip" :for="id">{{ props.label }}</VSLabel>
    <div :class="{ 'relative': props.maxLength }">
      <textarea
        :id="id" v-model="modelValue" :disabled="props.disabled" :readonly="props.readonly" :placeholder="props.placeholder"
        :maxlength="props.maxLength"
        class="block w-full rounded-md border-0 py-1.5 text-neutral-950 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm leading-6"
        :class="{
          'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
          'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled
        }"
      />
      <div v-if="props.maxLength" class="absolute bottom-0 right-0 p-2 pr-3 whitespace-nowrap text-xs leading-4 font-normal" :class="{ 'text-neutral-500': !props.disabled, 'text-neutral-250': props.disabled }">
        {{ modelValueLength }} / {{ props.maxLength }}
      </div>
    </div>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
    <VAlert v-if="validationResult" class="mt-2" dense :type="validationResult[0]" :message="validationResult[1]" />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { ValidationResult } from '../../utils/validations';
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import VAlert from '../dialogs/VAlert.vue';
import { useElementId } from '../../utils/utils';

defineOptions({
  name: 'VTextArea'
})

const props = withDefaults(
  defineProps<{
    id?: string
    label?: string
    labelTooltip?: string
    description?: string
    placeholder?: string
    disabled?: boolean
    readonly?: boolean
    maxLength?: number
    validator?: (value: string | null | undefined) => ValidationResult
  }>(),
  {
    id: undefined,
    description: undefined,
    label: undefined,
    labelTooltip: undefined,
    placeholder: undefined,
    readonly: false,
    disabled: false,
    maxLength: undefined,
    validator: () => undefined
  }
)

const id = useElementId(props.id)

const modelValue = defineModel<string | null | undefined>({ required: true })
const modelValueLength = computed(() => modelValue.value?.length ?? 0)

const validationActive = ref(false)
const validationResult = computed(() => props.validator && validationActive.value && props.validator(modelValue.value) || null)
</script>